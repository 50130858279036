<template>
  <div class="h-100">
    <vue-element-loading
      :active="loading"
      text="در حال ثبت تیکت جدید"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CRow>
      <CCol md="12">
        <span>عنوان</span>
        <CInput type="text" class="inputValue" v-model="title" />
      </CCol>
      <CCol md="12">
        <span>متن پیام</span>
        <CTextarea
          type="text"
          rows="20"
          v-model="description"
        ></CTextarea>
      </CCol>
      <CCol md="12">
        <input
          type="file"
          accept="image/*"
          attribute="capture"
          ref="tiketFile"
          style="display: none"
          @change="onFileChange"
        />
        <CButton
          color="secondary"
          @click="$refs.tiketFile.click()"
        >
          <CIcon name="cil-camera" style="color: white" size="lg" />
          <span> {{ tiketFileText }}</span>
        </CButton>
      </CCol>
    </CRow>
    <CButton block class="y-center-g-5 justify-content-center mt-5" @click="PostNewTiket()" color="primary">
      <CIcon name="cil-check" />
      <span>ارسال</span>
    </CButton>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "newTiket",
  data() {
    return {
      loading: false,
      tiketFileText: "فایل پیوست را انتخاب کنید.",
      title: "",
      description: "",
      tiketFile: null,
    };
  },
  methods: {
    ...mapActions("tiket", ["PostNew"]),

    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.tiketFile = files[0];
      this.tiketFileText = this.tiketFile.name;
    },
    async PostNewTiket() {
      let formData = new FormData();
      formData.append("Title", this.title);
      formData.append("Description", this.description);
      formData.append("File", this.tiketFile);
      this.loading = true;
      let result = await this.PostNew({ tiket: formData });
      this.loading = false;
      this.$emit("tiketInsertState", result.succeeded);
    },
  },
  created() {},
};
</script>

<style scoped>

</style>
