<template>
  <div class="w-100">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CRow class="position-relative">
      <CCol>
        <span>متن پیام</span>
        <CTextarea
          v-model="answerDescription"
          type="text"
          rows="20"
        ></CTextarea>
      </CCol>
      <CCol md="auto" class="position-absolute bottom-0 left-0 m-4">
        <input
          type="file"
          accept="image/*"
          attribute="capture"
          ref="tiketFile"
          style="display: none"
          @change="onFileChange"
        />
        <CButton
          color="secondary"
          size="sm"
          @click="$refs.tiketFile.click()"
        >
          <CIcon name="cil-camera"/>
          <span> {{ tiketFileText }}</span>
        </CButton>
      </CCol>
    </CRow>
    <CButton block class="y-center-g-5 justify-content-center mt-2" @click="PostAnswerTikte()" color="primary">
      <CIcon name="cil-check"/>
      <span>ارسال</span>
    </CButton>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "replayTiket",
  props: ["tiketId"],
  data() {
    return {
      loading: false,
      loadingText: "",
      tiketFileText: "فایل پیوست را انتخاب کنید.",
      title: "",
      number: "",
      createDate: "",
      answerDescription: "",
      tiketFile: null,
    };
  },
  methods: {
    ...mapActions("tiket", ["GetById", "PostAnswer"]),
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.tiketFile = files[0];
      this.tiketFileText = this.tiketFile.name;
    },

    async GetTiketById() {
      this.loading = true;
      this.loadingText = "در حال آماده سازی ...";
      let tiketInfo = await this.GetById({tiketId: this.tiketId});
      this.loading = false;
      if (tiketInfo.succeeded) {
        this.title = tiketInfo.data.title;
        this.number = tiketInfo.data.number;
        this.createDate = tiketInfo.data.dateTime;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات تیکت <br /><br />",
          type: "error",
        });
        this.$emit("closeModel");
      }
    },
    async PostAnswerTikte() {
      let formData = new FormData();
      formData.append("AdminAnswer", false);
      formData.append("TiketId", this.tiketId);
      formData.append("Description", this.answerDescription);
      formData.append("File", this.tiketFile);
      this.loading = true;
      let result = await this.PostAnswer({tiket: formData});
      this.loading = false;
      if (result.succeeded) {
        this.$notify({
          title: "پیام سیستم",
          text: "پاسخ تیکت ثبت شد <br /><br />",
          type: "success",
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: ` ${result.message}<br /><br />`,
          type: "error",
        });
      }
      this.$emit("closeModel");
    },
  },
  mounted() {
    this.GetTiketById();
  },
  created() {
  },
};
</script>

<style scoped>

</style>
